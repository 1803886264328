export const zinesList = [
  {
    path: "/zines/greenissue",
    title: "Green Issue",
    src: "/assets/green-issue-cover.jpg",
  },
  {
    title: "Yellow Issue",
    src: "/assets/yellow-issue-cover.jpg",
    path: "/zines/yellowissue",
  },
  {
    title: "Orange Issue",
    src: "/assets/orange-issue-cover.png",
    path: "/zines/orangeissue",
  },
];
