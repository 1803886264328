import { Chip, Divider, styled } from "@mui/material";
import { Layout } from "../Components/Layout";
import { palette } from "../Styles/theme";
import { useTranslatedContent } from "../Components/LanguageSwitcher/hook";
import { Size } from "../utils/types";

const TextWrapper = styled("div")(() => ({
  margin: "24px auto",
}));

const EventInfoText = styled("div")(() => ({
  margin: "24px auto",
}));

const Exhibitions = () => {
  const { renderText } = useTranslatedContent();

  return (
    <Layout size={Size.MID}>
      <div>
        <>
          <TextWrapper>
            {renderText({
              key: "pages.exhibitions.title",
            })}
            {renderText({
              key: "pages.exhibitions.description",
            })}
          </TextWrapper>
          <br />
          <>
            <Divider
              sx={{
                margin: "12px auto",
              }}
            >
              <Chip label="2024" size="small" />
            </Divider>
            <TextWrapper>
              {renderText({
                key: "pages.exhibitions.2024.stendhal.title",
              })}
              {renderText({
                key: "pages.exhibitions.2024.stendhal.subtitle",
              })}
              <br />
              <span>
                {renderText({
                  key: "pages.exhibitions.2024.stendhal.activities",
                })}
                {renderText({
                  key: "pages.exhibitions.2024.stendhal.link",
                  url: "https://www.instagram.com/thestendhalroom",
                })}
              </span>
              <Divider
                variant="middle"
                sx={{
                  borderColor: palette.secondary.main,
                  maxWidth: "50%",
                  textAlign: "center",
                  margin: "24px auto",
                }}
              />
              <EventInfoText>
                {renderText({
                  key: "pages.exhibitions.2024.mujeresquecortanypegan.info",
                })}
                <br />
                {renderText({
                  key: "pages.exhibitions.2024.mujeresquecortanypegan.address",
                })}
                <br />
                {renderText({
                  key: "pages.exhibitions.2024.mujeresquecortanypegan.description",
                })}
              </EventInfoText>
            </TextWrapper>
          </>
          <Divider
            sx={{
              margin: "12px auto",
            }}
          />
          <TextWrapper>
            {renderText({
              key: "pages.exhibitions.2024.mujeresquecortanypegan.title",
            })}
            {renderText({
              key: "pages.exhibitions.2024.mujeresquecortanypegan.subtitle",
            })}
            <br />
            <span>
              {renderText({
                key: "pages.exhibitions.2024.mujeresquecortanypegan.link",
                url: "https://www.instagram.com/mujeresquecortanypegan",
              })}
              {renderText({
                key: "pages.exhibitions.2024.mujeresquecortanypegan.link2",
                url: "https://www.instagram.com/thestendhalroom",
              })}
            </span>
            <Divider
              variant="middle"
              sx={{
                borderColor: palette.secondary.main,
                maxWidth: "50%",
                textAlign: "center",
                margin: "24px auto",
              }}
            />
            <EventInfoText>
              {renderText({
                key: "pages.exhibitions.2024.mujeresquecortanypegan.info",
              })}
              <br />
              {renderText({
                key: "pages.exhibitions.2024.mujeresquecortanypegan.address",
              })}
              <br />
              {renderText({
                key: "pages.exhibitions.2024.mujeresquecortanypegan.description",
              })}
            </EventInfoText>
          </TextWrapper>
        </>
        <Divider
          sx={{
            margin: "12px auto",
          }}
        />
        <>
          <TextWrapper>
            {renderText({
              key: "pages.exhibitions.2024.collabmad.title",
            })}
            {renderText({
              key: "pages.exhibitions.2024.collabmad.subtitle",
            })}
            <br />
            {renderText({
              key: "pages.exhibitions.2024.collabmad.link",
              url: "https://www.instagram.com/pequena_galeria_gallery",
            })}
            {renderText({
              key: "pages.exhibitions.2024.collabmad.link2",
              url: "https://www.instagram.com/collabmad",
            })}

            <Divider
              variant="middle"
              sx={{
                borderColor: palette.secondary.main,
                maxWidth: "50%",
                textAlign: "center",
                margin: "24px auto",
              }}
            />
            <EventInfoText>
              {renderText({
                key: "pages.exhibitions.2024.collabmad.info",
              })}
              <br />
              {renderText({
                key: "pages.exhibitions.2024.collabmad.address",
              })}
              <br />
              {renderText({
                key: "pages.exhibitions.2024.collabmad.description",
              })}
            </EventInfoText>
          </TextWrapper>
          <Divider
            sx={{
              margin: "12px auto",
            }}
          />
        </>
        <>
          <TextWrapper>
            {renderText({
              key: "pages.exhibitions.2024.one_twelve.title",
            })}
            {renderText({
              key: "pages.exhibitions.2024.one_twelve.subtitle",
            })}
            <br />
            {renderText({
              key: "pages.exhibitions.2024.one_twelve.link",
              url: "https://www.instagram.com/one_twelve_gallery",
            })}
            <Divider
              variant="middle"
              sx={{
                borderColor: palette.secondary.main,
                maxWidth: "50%",
                textAlign: "center",
                margin: "24px auto",
              }}
            />
            <EventInfoText>
              {renderText({
                key: "pages.exhibitions.2024.one_twelve.info",
              })}
              <br />
              {renderText({
                key: "pages.exhibitions.2024.one_twelve.address",
              })}
              <br />
              {renderText({
                key: "pages.exhibitions.2024.one_twelve.description",
              })}
            </EventInfoText>
          </TextWrapper>
          <Divider
            sx={{
              margin: "12px auto",
            }}
          />
        </>
        <TextWrapper>
          {renderText({
            key: "pages.exhibitions.2024.sortir_del_blanc.title",
          })}
          {renderText({
            key: "pages.exhibitions.2024.sortir_del_blanc.subtitle",
          })}
          <br />
          {renderText({
            key: "pages.exhibitions.2024.sortir_del_blanc.link",
            url: "https://tallerbalam.com/evento/sortir-del-blanc-exposicion-colectiva-collage/",
          })}
          <Divider
            variant="middle"
            sx={{
              borderColor: palette.secondary.main,
              maxWidth: "50%",
              textAlign: "center",
              margin: "24px auto",
            }}
          />
          <EventInfoText>
            {renderText({
              key: "pages.exhibitions.2024.sortir_del_blanc.info",
            })}
            <br />
            {renderText({
              key: "pages.exhibitions.2024.sortir_del_blanc.address",
            })}
            <br />
            {renderText({
              key: "pages.exhibitions.2024.sortir_del_blanc.activities",
            })}
            <br />
            {renderText({
              key: "pages.exhibitions.2024.sortir_del_blanc.description",
            })}
          </EventInfoText>
        </TextWrapper>
        <Divider
          sx={{
            margin: "12px auto",
          }}
        />
        <>
          <TextWrapper>
            {renderText({
              key: "pages.exhibitions.2024.collage_o_rama.title",
            })}
            {renderText({
              key: "pages.exhibitions.2024.collage_o_rama.subtitle",
            })}
            <br />
            {renderText({
              key: "pages.exhibitions.2024.collage_o_rama.link",
              url: "https://www.specialagentcollagecollective.com/collage-o-rama?mc_cid=e3b1e86cdf&mc_eid=60c6e07849J",
            })}
            <br />
            <Divider
              variant="middle"
              sx={{
                borderColor: palette.secondary.main,
                maxWidth: "50%",
                textAlign: "center",
                margin: "24px auto",
              }}
            />
            <br />
            {renderText({
              key: "pages.exhibitions.2024.collage_o_rama.info",
            })}
            <br />
            {renderText({
              key: "pages.exhibitions.2024.collage_o_rama.address",
            })}
            <br />
            {renderText({
              key: "pages.exhibitions.2024.collage_o_rama.activities",
            })}
            <br />
            {renderText({
              key: "pages.exhibitions.2024.collage_o_rama.description",
            })}
          </TextWrapper>
        </>

        <Divider
          sx={{
            margin: "12px auto",
          }}
        />
        <>
          <TextWrapper>
            {renderText({
              key: "pages.exhibitions.2024.kolash.title",
            })}
            {renderText({
              key: "pages.exhibitions.2024.kolash.subtitle",
            })}
            <br />
            {renderText({
              key: "pages.exhibitions.2024.kolash.link",
              url: "https://www.somoscomplices.com/",
            })}
            <br />

            <Divider
              variant="middle"
              sx={{
                borderColor: palette.secondary.main,
                maxWidth: "50%",
                textAlign: "center",
                margin: "24px auto",
              }}
            />
            <br />
            {renderText({
              key: "pages.exhibitions.2024.kolash.info",
            })}
            <br />
            {renderText({
              key: "pages.exhibitions.2024.kolash.address",
            })}
            <br />
            {renderText({
              key: "pages.exhibitions.2024.kolash.activities",
            })}
            <br />
            {renderText({
              key: "pages.exhibitions.2024.kolash.description",
            })}
          </TextWrapper>
        </>
      </div>
    </Layout >
  );
};

export default Exhibitions;
